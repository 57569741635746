@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', 'San-Serif'

} 

  .footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 9%;
    background: #174e76;
  }

  .footer-text p{
    font-size: 0.8rem;
  }

  .footer-icon a{
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    background: #00abf0;
    border-radius: .6rem;
    border: .1rem solid #00abf0;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
  }

  .footer-icon a::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #174e76;
    z-index: -1;
    transition: .5s;
  }

  .footer-icon a:hover::before {
    width: 100%;
  }

  .footer-icon a i{
    font-size: 1.2rem;
    color: #174e76;
  }

  .footer-icon a:hover i{
    color: #00abf0;
  }

  @media (max-width: 768px) {
    .footer-icon{
      transform: scale(0.75);
      align-items: center;
      justify-content: center;
      display: inline-flex;
    }
    .footer-text p{
      font-size: 0.6rem;
    }
  }

