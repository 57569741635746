@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', 'San-Serif'

} 


.experience-header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    white-space: nowrap;
    font-size: 30px;
}

.experience-title{
    color: #00abf0;
}

h1,
h2,
h3{
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
.section {
  width: 100vw;
  margin: 5rem auto;
  max-width: 1170px;
}


.icon {
  font-size: 3rem;
  color: #00abf0;
}

.section-center {
  margin: 0 auto;
  margin-top: 4rem;
  width: 90vw;
  height: 450px;
  max-width: 800px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
}

article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
article.lastSlide {
  transform: translateX(-100%);
}
article.nextSlide {
  transform: translateX(100%);
}

.person-img {
  border-radius: 50%;
  margin-bottom: 10px;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 4px solid hsl(210, 31%, 80%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

article h4 {
  text-transform: uppercase;
  color: #00abf0;
  margin-bottom: 10px;
}
.title {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
  color: grey;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
}
.title h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.text {
  max-width: 40em;
  margin: 0 auto;
  margin-top: 2rem;
  line-height: 1.75;
  color: #fff;
  font-size: 13.75px;
}

.prev,
.next {
  position: absolute;
  top: unset;
  bottom: 40px;
  transform: unset;
  background: transparent;
  color: #00abf0;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #00abf0;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s linear;
  overflow: hidden;
}

.prev:hover,
.next:hover {
  color: #081b29;
  overflow: hidden;
}

.prev::before,
.next::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: #00abf0;
  z-index: -1;
  transition: 0.5s;
}

.prev:hover::before,
.next:hover::before {
  width: 100%;
}

.prev {
  left: 0;
}
.next {
  right: 0;
}
@media screen and (max-width: 768px) {
  .experience-header{
    font-size: 26px;;
  }
  .section {
    width: 95vw;
  }
  .prev,
  .next {
    top: calc(70% + 100px);
    transform: translateY(20%);
    bottom: unset;
    width: 25px;
    height: 25px;
    font-size: 12px;
  }

  .text {
    font-size: 11px;
  }
}