@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', 'San-Serif'

} 

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  width: 100%;
  padding: 0 2rem;
  background-color: #081b29;
  color:#ededed;
  border-radius: 10px; 
}

.logo {
  width: 40px;
  margin-top: 5px;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999; 
  padding: 0 8%;
}


nav a {
  margin: 0 2rem;
  color: #ededed;
  cursor: pointer;
  text-decoration: none;
}

nav a:hover{
  color: #00abf0;
}

.active {
  color: #00abf0;
}

header .nav-btn {
  padding: 5px;
  margin-top: 2px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #ededed;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
  z-index: 1;
}

header .nav-btn:hover {
  color:#00abf0;
}

@media only screen and (max-width: 1024px){
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    z-index: 999;
  }
  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: #081b29;
    transition: 1s;
    transform: translateY(-100vh);
    z-index: 998;
  }
  header .responsive_nav{
    transform: translateY(0);
    z-index: 999;
    width: 100%;

  }
  nav .nav-close-btn{
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 1000; 
  }
  nav a{
    font-size: 1.5rem;
    z-index: 999;
  }
}